









/* close on class click */

$( document ).on( 'click', '.wishlist-modal-create-close', function( event ){

    event.preventDefault();
        
    const stepped = $( '#wishlist-create-stepped' ).val().trim();

    if( stepped === 'yes' ){
        show_modal( '#wishlist-modal-action' );
    } else {
        close_modal();
    }

});


















 


/* .wishlist-action-trigger */

$( document ).on( 'click', '.wishlist-create-trigger', function( event ){

    show_modal( '#wishlist-modal-create' );

});

 


 




$( document ).on( 'submit', '#wishlist-create-form', ( event ) => {
    
    event.preventDefault();

    const stepped = $( '#wishlist-create-stepped' ).val().trim();
    
    close_modal();

    launch_toast({ message: 'Wish List Created!', theme: 'success' });

    const ajax_data = {
        action: 'wish_list_create',
        nonce: $( '#wish_list_create_field' ).val(),
        list_name: $( '#wishlist-create-name' ).val().trim(),
        list_private: $( '#wishlist-create-private' ).is( ':checked' )
    }

    $.ajax({
        url: '/wp-admin/admin-ajax.php',
        type: 'post',
        data: ajax_data,
        dataType: 'json',
        cache: false,
        headers: { 'cache-control': 'no-cache' },
        success: function( response ){
    
            if( response ){

                if( stepped === 'yes' ){
                    
                    $( '#wishlist-modal-lists' ).append( '<li class="wishlist-action-list wishlist-action-list-' + response['list_id'] + '"><input class="wishlist-action-list-count" id="wishlist-action-list-count-' + response['list_id'] + '" type="hidden" value="0" data-count="0" required readonly><label><input class="wishlist-modal-list-item wishlist-modal-list-checkbox" name="wishlist-checkbox-' + response['list_id'] + '" type="checkbox" value="' + response['list_id'] + '"><span class="wishlist-modal-list-item-label">' + response['list_name'] + ' <span class="wishlist-modal-list-items-count">(0)</span></span></label></li>' );

                    let wishlist_create_temp_data = window.wishlist_data;

                    const list_id = response['list_id'] + ''; 

                    wishlist_create_temp_data[list_id] = [];

                    window.wishlist_data = wishlist_create_temp_data;
                    
                    show_modal( '#wishlist-modal-action' );

                    $( '#wishlist-create-name' ).val( $( '#wishlist-create-name' ).data( 'default' ));

                    const items_count = $( '.wishlist-modal-list-item' ).length;

                    if( items_count >= 5 ){
                        $( '.wishlist-create-trigger' ).hide().remove();
                    }

                } else {
                  
                    window.location.href = window.location.href.split( '#' )[0].split( '?' )[0] + '?token=' + response['list_token'];

                }

            } else {

                location.reload();

            }

        }
    });

});