
/* #wishlist-edit-delete-trigger */

    $( document ).on( 'click', '#wishlist-edit-delete-trigger', function( event ){

        event.preventDefault();

        $( this ).hide()

        $( '#wishlist-edit-delete-confirm' ).show();

    });







/* #wishlist-edit-delete-confirm-trigger */

    $( document ).on( 'click', '#wishlist-edit-delete-confirm-trigger', function( event ){

        event.preventDefault();

        $( '#wishlist-edit-delete-form' ).submit();

    });

/* form #wishlist-edit-delete-form */

    $( document ).on( 'submit', '#wishlist-edit-delete-form', ( event ) => {
        
        event.preventDefault();

        const ajax_data = {
            action: 'wish_list_delete',
            nonce: $( '#wish_list_delete_field' ).val(),
            list_id: $( '#wishlist-delete-list-id' ).val().trim()
        }
        
        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            type: 'post',
            data: ajax_data,
            dataType: 'json',
            cache: false,
            headers: { 'cache-control': 'no-cache' },
            success: function( response ){
        
                if( response ){

                    if( response['return_link'] ){
            
                        window.location.href = response['return_link'];

                    } else {
                     
                        location.reload();

                    }

                } else {

                    location.reload();

                }

            }
        });

    });








 







/* form #wishlist-edit-form */

    $( document ).on( 'submit', '#wishlist-edit-form', ( event ) => {
            
        event.preventDefault();

        const ajax_data = {
            action: 'wish_list_edit',
            nonce: $( '#wish_list_edit_field' ).val(),
            list_id: $( '#wishlist-edit-list-id' ).val().trim(),
            next_name: $( '#wishlist-edit-name' ).val().trim(),
            next_private: $( '#wishlist-edit-private' ).is( ':checked' )
        }
        
        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            type: 'post',
            data: ajax_data,
            dataType: 'json',
            cache: false,
            headers: { 'cache-control': 'no-cache' },
            success: function( response ){
        
                if( response['edited'] && response['name'] ){

                    $( '.wishlist-page-header-name' ).text( response['name'] );
                    
                    $( '#wishlist-edit-name' ).val( response['name'] );
                    $( '#wishlist-edit-name' ).data( 'current', response['name'] );
                    $( '#wishlist-edit-button-save' ).attr( 'disabled', true );

                    if( parseInt( response['private'], 10 ) > 0 ) {
                        
                        $( '#wishlist-edit-private' ).data( 'current', 'yes' );

                        $( '.wishlist-privacy-public' ).removeClass( 'active' );
                        $( '.wishlist-privacy-private' ).addClass( 'active' );

                        $( '.wishlist-share-trigger' ).addClass( 'wishlist-action-bar-button-disabled' );

                    } else {
                        
                        $( '#wishlist-edit-private' ).data( 'current', 'no' );

                        $( '.wishlist-privacy-public' ).addClass( 'active' );
                        $( '.wishlist-privacy-private' ).removeClass( 'active' );

                        $( '.wishlist-share-trigger' ).removeClass( 'wishlist-action-bar-button-disabled' );

                    }

                    close_modal();
                
                    launch_toast({ message: 'Wish List Updated!', theme: 'success' });

                } else {

                    launch_toast({ message: 'Please refresh and try again.', theme: 'delete' });

                }

            }
        });

    });










/* enable/disable form if changes made */
 
    $( '#wishlist-edit-name, #wishlist-edit-private' ).on( 'change paste keyup', function() {

        const current_name = $( '#wishlist-edit-name' ).data( 'current' ).trim();
        const next_name = $( '#wishlist-edit-name' ).val().trim();
        
        const current_private = $( '#wishlist-edit-private' ).data( 'current' ).trim().toLowerCase() === 'yes';
        const next_private = $( '#wishlist-edit-private' ).is( ':checked' );
 
        if( next_name === current_name && next_private === current_private ){

            $( '#wishlist-edit-button-save' ).attr( 'disabled', true );

        } else {
            
            $( '#wishlist-edit-button-save' ).attr( 'disabled', false );

        }

    });

















/* .wishlist-edit-trigger */

    $( document ).on( 'click', '.wishlist-edit-trigger', function( event ){

        event.preventDefault();

    /* resets */

        let current_name = $( '#wishlist-edit-name' ).data( 'current' ) + '';
        
        if( current_name ){
            current_name = current_name.trim();
        }

        const current_private = $( '#wishlist-edit-private' ).data( 'current' ).trim().toLowerCase() === 'yes';

        $( '#wishlist-edit-name' ).val( current_name ) + '';

        if( current_private === true ) {            
            $( '#wishlist-edit-private' ).prop( 'checked', true );
        } else {
            $( '#wishlist-edit-private' ).prop( 'checked', false );
        }

        $( '#wishlist-edit-delete-trigger' ).show()
        $( '#wishlist-edit-delete-confirm' ).hide();

    /* launch modal */

        show_modal( '#wishlist-modal-edit' );

    });
