
/* wishlist_hide_drag */

    const wishlist_hide_drag = () => {
        $( '.wishlist-item-drag-handle' ).hide();
    }

/* wishlist_show_drag */

    const wishlist_show_drag = () => {
        $( '.wishlist-item-drag-handle' ).show();
    }

/* wishlist_save_order */

    const wishlist_save_order = ( new_order ) => {
        
        const ajax_data = {
            action: 'wish_list_save_order',
            list_id: $( '#wishlist-products-list-id' ).val(),
            nonce: $( '#wish_list_products_field' ).val(),
            new_order: new_order
        }
        
        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            type: 'post',
            data: ajax_data,
            dataType: 'json',
            cache: false,
            headers: { 'cache-control': 'no-cache' }
        });

    }

/* wishlist_set_order */

    const wishlist_set_order = ( next ) => {

        if( $( '.wishlist-item:visible' ).length > 1 ) {

            let order = [];

            next.forEach( ( element, index ) => {

                const product_id = parseInt( next[index], 10 );
                const position = parseInt( ( index + 1 ), 10 );

                order.push({
                    product_id,
                    position
                });

                $( '.wishlist-item[data-id="' + product_id + '"]' ).attr( 'data-rank', position );

            });
    
            wishlist_save_order( order );

        }

    }

/* enable drag and drop if element exists */

    if( $( '#sortable' ).length > 0 ){

        const sortable_element = document.getElementById( 'sortable' );
        
        new Sortable( sortable_element, { 
            handle: '.wishlist-item-drag-handle',
            preventOnFilter: false, 
            animation: 150,
            forceFallback: true,
            scroll: true,
            bubbleScroll: true,
            store: {
                set: function( data ) {
                    wishlist_set_order( data.toArray() );
                }
            }
        });

    }
  