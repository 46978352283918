
/* wishlist_container_height */

    const wishlist_container_height = () => {

        let height = 0;
        
        $( '.wishlist-item:visible' ).each( function( index ) {

            const item_height = parseInt( $( this ).outerHeight( true ), 10 );

            height = height += item_height;

        });
        
        return height;
    }

/*  wishlist_count_results */

    const wishlist_count_results = () => {

        const count = $( '.wishlist-item:visible' ).length;
        const container_height = wishlist_container_height();
  
        $( '.wishlist-products-list' ).height( container_height );

        wishlist_empty( count );

    }

/*  wishlist_empty */

    const wishlist_empty = ( count = 0 ) => {

        if( count < 1 ) {

            $( '.wishlist-empty' ).addClass( 'visible' ).addClass( 'updated' );

        } else {

            $( '.wishlist-empty' ).removeClass( 'visible' ).removeClass( 'updated' );

        }

    }