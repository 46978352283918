
/*  filter_data default */

    let filter_data = {
        'category': 'all',
        'stock': 'show',
        'price': 9999,
        'delivery': [],
        'exclude': [],
        'discount': []
    };






/* clear_wishlist_filters */
  
    const clear_wishlist_filters = () => {
        
        $( '.wishlist-filters [type=checkbox]' ).prop( 'checked', false );

        $( '.wishlist-filters [type=radio]' ).prop( 'checked', false );

        $( '#filter-category-1, #filter-stock-1' ).prop( 'checked', true );

        filter_data = {
            'category': 'all',
            'stock': 'show',
            'price': 9999,
            'delivery': [],
            'exclude': [],
            'discount': []
        }

        wishlist_filter_items();

        wishlist_count_results();

    }





    $( document ).on( 'click', '.clear-wishlist-filters', function( event ){

        event.preventDefault();

        clear_wishlist_filters();

    });



    







/* wishlist_filter_set_categories */
  
    const wishlist_filter_set_categories = () => {

        let categories = [];

        $( '.wishlist-filter-option-category li' ).closest( 'li' ).hide();
        $( '.wishlist-filter-option-category input[value="all"]' ).closest( 'li' ).show();

        $( '.wishlist-item' ).each( function() {

            const item_category = $( this ).data( 'category' );

            if( !categories.includes( item_category )) {

                $( '.wishlist-filter-option-category input[value="' + item_category + '"]' ).closest( 'li' ).show();

                categories.push( item_category );

            }

        });

    }
     








/* wishlist_filter_items */
  
    const wishlist_filter_items = () => {
 
    /* loop all list items */

        $( '.wishlist-item' ).each( function() {
     
            let hidden = false;

            const item_category = $( this ).data( 'category' );
            const item_stock_status = $( this ).data( 'stock-status' );
            const item_stock_type = $( this ).data( 'stock-type' );
            const item_sale = $( this ).data( 'sale' );
            const item_price_dropped = $( this ).data( 'price-dropped' );
            const item_price = parseFloat( $( this ).data( 'price' ));
            const item_delivery = $( this ).data( 'delivery' );

        /* filter item by category */

            if( filter_data['category'] != 'all' && item_category != filter_data['category'] ) {
                hidden = true;
            }

        /* filter item by stock */
 
            if( filter_data['stock'] === 'hide' && item_stock_status != 'in_stock' ) {
                hidden = true;
            }

        /* filter item by sale */
 
            if( filter_data['discount'].includes( 'sale' ) && item_sale != 'yes' ) {
                hidden = true;
            }

        /* filter item by price drop */
 
            if( filter_data['discount'].includes( 'price-drop' ) && item_price_dropped != 'yes' ) {
                hidden = true;
            }

        /* price ranges */

            if( filter_data['price'] === 9999 && item_price > 9999999999 ) {
                hidden = true;
            }

            if( filter_data['price'] === 15 && item_price > 15 ) {
                hidden = true;
            }

            if( filter_data['price'] === 25 && item_price > 25 ) {
                hidden = true;
            }

            if( filter_data['price'] === 50 && item_price > 50 ) {
                hidden = true;
            }

            if( filter_data['price'] === 51 && item_price < 50 && item_price < 9999999999 ) {
                hidden = true;
            }
    
        /* delivery */

            if( filter_data['delivery'].length ) {
                
                if( filter_data['delivery'].includes( 'next_day' ) && item_delivery !== 'next_day' ) {
                    hidden = true;
                }
    
            }
 
        /* exclusion by item type */
    
            if( filter_data['exclude'].includes( 'pre_order' ) && item_stock_type === 'pre_order' ) {
                hidden = true;
            }

            if( filter_data['exclude'].includes( 'pre_order' ) && item_stock_type === 'second_wave' ) {
                hidden = true;
            }

            if( filter_data['exclude'].includes( 'restock_item' ) && item_stock_type === 'restock_item' ) {
                hidden = true;
            }

        /* show or hide items */

            if( !hidden ) {                
                $( this ).show();
            }
            else {        
                $( this ).hide();
            }

        });

    /* re-sort items */

        const sort_by = $( '#wishlist-sort' ).val();

        wishlist_sort_items_init( sort_by );

    }











    
/* wishlist_set_filter_data */
  
    const wishlist_set_filter_data = ( data ) => {
    
        const { group, value, checked, single } = data;
        
        if( single ){
            
            filter_data[group] = value;

        }
        else {

            if( filter_data[group].includes( value )) {

                filter_data[group].splice( filter_data[group].indexOf( value ), 1 );

            }

            if( checked ){
                
                filter_data[group].push( value );

            }

        }

    }













/* .wishlist-filter-option */

    $( document ).on( 'change', '.wishlist-filter-option li input', function( event ){

        event.preventDefault();
            
        const group = $( this ).closest( '.wishlist-filter-option' ).data( 'filter-group' ) || false;
        const single = $( this ).is( 'input[type="radio"]' ) || false;
        const checked = $( this ).is( ':checked' ) || false;        
        let value = $( this ).val().trim();
        
        if( [ 'price' ].includes( group ) ){
            value = parseInt( value, 10 )
        }

        wishlist_set_filter_data({ group, value, checked, single });

        wishlist_filter_items();

        wishlist_count_results();

    });









/* show hide dropdown */

    $( document ).on( 'click', '.wishlist-filter-trigger', function( event ){

        event.preventDefault();
        
        wishlist_filter_set_categories();

        $( '.wishlist-filter-zone' ).toggle();
        $( '.wishlist-filter-trigger' ).toggleClass( 'active' );

    });

    $( document ).mouseup(( event ) => {
            
        if( !$( '.wishlist-filter-trigger' ).is( event.target ) && $( '.wishlist-filter-zone' ).has( event.target ).length === 0 ) {
            
            $( '.wishlist-filter-zone' ).hide();
            $( '.wishlist-filter-trigger' ).removeClass( 'active' );

        }
        
    });

    $( document ).keyup( ( event ) => {
        
        if( event.key === 'Escape' ) {
            
            $( '.wishlist-filter-zone' ).hide();
            $( '.wishlist-filter-trigger' ).removeClass( 'active' );

        }

    });