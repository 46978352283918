




/* .wishlist-remove-trigger */

    $( document ).on( 'click', '.wishlist-remove-trigger', function( event ){

        event.preventDefault();

        const item_id = $( this ).closest( '.wishlist-item' ).data( 'id' );
        const item_name = $( this ).closest( '.wishlist-item' ).data( 'name' );

        //console.log({ item_id, item_name });

        $( '#wishlist-modal-remove-name' ).text( item_name );
        $( '#wishlist-remove-item-id' ).val( item_id );

        show_modal( '#wishlist-modal-remove' );

    });




/* form #wishlist-remove-form */

    $( document ).on( 'submit', '#wishlist-remove-form', ( event ) => {
            
        event.preventDefault();

        const ajax_data = {
            action: 'wish_list_remove',
            nonce: $( '#wish_list_remove_field' ).val(),
            list_id: $( '#wishlist-remove-list-id' ).val().trim(),
            item_id: $( '#wishlist-remove-item-id' ).val().trim(),
        }

        if( ajax_data['item_id'] ) {

        /* hide item */

            $( '.wishlist-item[data-id="' + ajax_data['item_id'] + '"]' ).remove();

        /* update counters */

            const updated_count = $( '.wishlist-item' ).length;

            $( '.wishlist-page-header-count' ).html( '(' + updated_count + ')' );

        /* re-sort items */
    
            const sort_by = $( '#wishlist-sort' ).val();
    
            wishlist_sort_items_init( sort_by );
        
        }

        close_modal();

        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            type: 'post',
            data: ajax_data,
            dataType: 'json',
            cache: false,
            headers: { 'cache-control': 'no-cache' },
            success: function( response ){
                
                launch_toast({ message: 'Item Removed successfully!', theme: 'success' });
                
            },
            error: function( response, status, error ){

                launch_toast({ message: 'Please refresh and try again.', theme: 'delete' });

            }
        });    

    });