jQuery(document).ready(function($){
/* toast_timer */

    let toast_timer_open;
    let toast_timer_close;

    const run_toast_timer_open = () => {
        toast_timer_open = window.setTimeout( () => {
            $( '#wishlist-toast' ).show();
            run_toast_timer_close();
        }, 200 );
    }

    const run_toast_timer_close = () => {
        toast_timer_close = window.setTimeout( () => {
            close_toast();
        }, 5000 );
    }
    
/* launch_toast */

    const launch_toast = ( data ) => {

        close_toast();

        clearTimeout( toast_timer_open );
        clearTimeout( toast_timer_close );

        const { message, theme } = data;
        
        if( !message || !theme ) return;

        $( '#wishlist-toast-container' ).removeClass().addClass( theme );
        $( '#wishlist-toast-header span' ).text( message );

        run_toast_timer_open();
    }

/* close_toast */

    const close_toast = () => {

        $( '#wishlist-toast' ).hide();

    }

/* close on toast click */

    $( document ).on( 'click', '#wishlist-toast-close-trigger', function( event ){

        close_toast();
    
    });
    

/*  filter_data default */

    let filter_data = {
        'category': 'all',
        'stock': 'show',
        'price': 9999,
        'delivery': [],
        'exclude': [],
        'discount': []
    };






/* clear_wishlist_filters */
  
    const clear_wishlist_filters = () => {
        
        $( '.wishlist-filters [type=checkbox]' ).prop( 'checked', false );

        $( '.wishlist-filters [type=radio]' ).prop( 'checked', false );

        $( '#filter-category-1, #filter-stock-1' ).prop( 'checked', true );

        filter_data = {
            'category': 'all',
            'stock': 'show',
            'price': 9999,
            'delivery': [],
            'exclude': [],
            'discount': []
        }

        wishlist_filter_items();

        wishlist_count_results();

    }





    $( document ).on( 'click', '.clear-wishlist-filters', function( event ){

        event.preventDefault();

        clear_wishlist_filters();

    });



    







/* wishlist_filter_set_categories */
  
    const wishlist_filter_set_categories = () => {

        let categories = [];

        $( '.wishlist-filter-option-category li' ).closest( 'li' ).hide();
        $( '.wishlist-filter-option-category input[value="all"]' ).closest( 'li' ).show();

        $( '.wishlist-item' ).each( function() {

            const item_category = $( this ).data( 'category' );

            if( !categories.includes( item_category )) {

                $( '.wishlist-filter-option-category input[value="' + item_category + '"]' ).closest( 'li' ).show();

                categories.push( item_category );

            }

        });

    }
     








/* wishlist_filter_items */
  
    const wishlist_filter_items = () => {
 
    /* loop all list items */

        $( '.wishlist-item' ).each( function() {
     
            let hidden = false;

            const item_category = $( this ).data( 'category' );
            const item_stock_status = $( this ).data( 'stock-status' );
            const item_stock_type = $( this ).data( 'stock-type' );
            const item_sale = $( this ).data( 'sale' );
            const item_price_dropped = $( this ).data( 'price-dropped' );
            const item_price = parseFloat( $( this ).data( 'price' ));
            const item_delivery = $( this ).data( 'delivery' );

        /* filter item by category */

            if( filter_data['category'] != 'all' && item_category != filter_data['category'] ) {
                hidden = true;
            }

        /* filter item by stock */
 
            if( filter_data['stock'] === 'hide' && item_stock_status != 'in_stock' ) {
                hidden = true;
            }

        /* filter item by sale */
 
            if( filter_data['discount'].includes( 'sale' ) && item_sale != 'yes' ) {
                hidden = true;
            }

        /* filter item by price drop */
 
            if( filter_data['discount'].includes( 'price-drop' ) && item_price_dropped != 'yes' ) {
                hidden = true;
            }

        /* price ranges */

            if( filter_data['price'] === 9999 && item_price > 9999999999 ) {
                hidden = true;
            }

            if( filter_data['price'] === 15 && item_price > 15 ) {
                hidden = true;
            }

            if( filter_data['price'] === 25 && item_price > 25 ) {
                hidden = true;
            }

            if( filter_data['price'] === 50 && item_price > 50 ) {
                hidden = true;
            }

            if( filter_data['price'] === 51 && item_price < 50 && item_price < 9999999999 ) {
                hidden = true;
            }
    
        /* delivery */

            if( filter_data['delivery'].length ) {
                
                if( filter_data['delivery'].includes( 'next_day' ) && item_delivery !== 'next_day' ) {
                    hidden = true;
                }
    
            }
 
        /* exclusion by item type */
    
            if( filter_data['exclude'].includes( 'pre_order' ) && item_stock_type === 'pre_order' ) {
                hidden = true;
            }

            if( filter_data['exclude'].includes( 'pre_order' ) && item_stock_type === 'second_wave' ) {
                hidden = true;
            }

            if( filter_data['exclude'].includes( 'restock_item' ) && item_stock_type === 'restock_item' ) {
                hidden = true;
            }

        /* show or hide items */

            if( !hidden ) {                
                $( this ).show();
            }
            else {        
                $( this ).hide();
            }

        });

    /* re-sort items */

        const sort_by = $( '#wishlist-sort' ).val();

        wishlist_sort_items_init( sort_by );

    }











    
/* wishlist_set_filter_data */
  
    const wishlist_set_filter_data = ( data ) => {
    
        const { group, value, checked, single } = data;
        
        if( single ){
            
            filter_data[group] = value;

        }
        else {

            if( filter_data[group].includes( value )) {

                filter_data[group].splice( filter_data[group].indexOf( value ), 1 );

            }

            if( checked ){
                
                filter_data[group].push( value );

            }

        }

    }













/* .wishlist-filter-option */

    $( document ).on( 'change', '.wishlist-filter-option li input', function( event ){

        event.preventDefault();
            
        const group = $( this ).closest( '.wishlist-filter-option' ).data( 'filter-group' ) || false;
        const single = $( this ).is( 'input[type="radio"]' ) || false;
        const checked = $( this ).is( ':checked' ) || false;        
        let value = $( this ).val().trim();
        
        if( [ 'price' ].includes( group ) ){
            value = parseInt( value, 10 )
        }

        wishlist_set_filter_data({ group, value, checked, single });

        wishlist_filter_items();

        wishlist_count_results();

    });









/* show hide dropdown */

    $( document ).on( 'click', '.wishlist-filter-trigger', function( event ){

        event.preventDefault();
        
        wishlist_filter_set_categories();

        $( '.wishlist-filter-zone' ).toggle();
        $( '.wishlist-filter-trigger' ).toggleClass( 'active' );

    });

    $( document ).mouseup(( event ) => {
            
        if( !$( '.wishlist-filter-trigger' ).is( event.target ) && $( '.wishlist-filter-zone' ).has( event.target ).length === 0 ) {
            
            $( '.wishlist-filter-zone' ).hide();
            $( '.wishlist-filter-trigger' ).removeClass( 'active' );

        }
        
    });

    $( document ).keyup( ( event ) => {
        
        if( event.key === 'Escape' ) {
            
            $( '.wishlist-filter-zone' ).hide();
            $( '.wishlist-filter-trigger' ).removeClass( 'active' );

        }

    });

 /* sort_map */

    const sort_map = {
        rank: "rank",
        lowest: "price",
        highest: "price",
        date: "date",
        name: "name",
        stock: "stock-qty",
        popularity: "popularity",
    };

 /* wishlist_get_item_offset */

    const wishlist_get_item_offset = ( heights, key ) => {

        const combined = heights.slice( 0, key );

        const offset = combined.reduce(( a, b ) => a + b, 0 );

        return offset;

    }

 /* wishlist_render_sorted_items */

    const wishlist_render_sorted_items = ( sorted ) => {
   
        const heights = sorted.map( item => {
            return parseInt( item['height'], 10 );
        });
        
        for( let i = 0; i < sorted.length; i++ ) {
        
            const key = i;
            const id = sorted[i]['id'] + '';

            const new_height = wishlist_get_item_offset( heights, key );

            $( '.wishlist-item:visible[data-id="' + id + '"]' ).css({ 'position' : 'absolute', 'left' : '0', 'top' : new_height + 'px' });

        }

    }

 /* wishlist_sort_items */

    const wishlist_sort_items = ( data, sort_by ) => {

    /* order strings alphabetically */

        if( [ 'name' ].includes( sort_by )){

            return data.sort((a, b) => a.key.localeCompare( b.key ))

        }
    
    /* sort numbers biggest to smallest */

        else if( [ 'date', 'highest', 'stock', 'popularity' ].includes( sort_by )){

            return data.sort( function( a, b ) {
                return b.key - a.key;
            });

        }
        
    /* sort numbers smallest to biggest by default */

        else {
 
            return data.sort( function( a, b ) {
                return a.key - b.key;
            });

        }

    }

 /* wishlist_sort_items_init */

    const wishlist_sort_items_init = ( sort_by = 'rank' ) => {

        wishlist_start_loading();

        let data = [];

        if( sort_by === 'rank' ){

            wishlist_show_drag();
            
            $( '.wishlist-item:visible' ).css({ 'position' : 'relative', 'left' : 'auto', 'top' : 'auto' });
            
            setTimeout( () => {

                wishlist_end_loading();
                
            }, 200 );

        } else {

            wishlist_hide_drag();

            let sort_key = sort_by;

            if( sort_map[sort_by] ){
                sort_key = sort_map[sort_by];
            }

            $( '.wishlist-item:visible' ).each( function( index ) {

                const item_id = $( this ).data( 'id' );
                const item_data = $( this ).data( sort_key );
                const item_height = parseInt( $( this ).outerHeight( true ), 10 );

                data.push({ id: item_id, key: item_data, height: item_height });

            });

            const sorted = wishlist_sort_items( data, sort_by );
            
            setTimeout( () => {
            
                $( '.wishlist-products-loading' ).hide().remove();
                
                wishlist_render_sorted_items( sorted );

                wishlist_count_results();

                wishlist_end_loading();
                
            }, 200 );
        
        }

    }

/* change sort order */

    $( document ).on( 'click', '.wishlist-sort-list li a', function( event ){

        event.preventDefault();

        const sort_by = $( this ).data( 'sort' );
        const sort_text = $( this ).text().trim();

        $( '.wishlist-sort-text' ).text( sort_text );

        wishlist_sort_items_init( sort_by );
        
        $( '.wishlist-sort-zone' ).hide();
        $( '.wishlist-sort-trigger' ).removeClass( 'active' );
        
    });

/* sorting loaders */

    const wishlist_start_loading = () => {
        
        $( '.wishlist-products-list' ).append( '<div class="wishlist-products-loading"></div>')

    }

    const wishlist_end_loading = () => {            
        
        $( '.wishlist-products-loading' ).hide().remove();

    }

/* show hide dropdown */

    $( document ).on( 'click', '.wishlist-sort-trigger', function( event ){

        event.preventDefault();

        $( '.wishlist-sort-zone' ).toggle();
        $( '.wishlist-sort-trigger' ).toggleClass( 'active' );

    });

    $( document ).mouseup(( event ) => {
            
        if( !$( '.wishlist-sort-trigger' ).is( event.target ) && $( '.wishlist-sort-zone' ).has( event.target ).length === 0 ) {
            
            $( '.wishlist-sort-zone' ).hide();
            $( '.wishlist-sort-trigger' ).removeClass( 'active' );

        }
        
    });

    $( document ).keyup( ( event ) => {
        
        if( event.key === 'Escape' ) {
            
            $( '.wishlist-sort-zone' ).hide();
            $( '.wishlist-sort-trigger' ).removeClass( 'active' );
            
        }

    });
 

/* wishlist_temp_data */

let wishlist_temp_data = [];

/* wishlist_save_data */

const wishlist_save_data = () => {
        
    window.wishlist_data = wishlist_temp_data;

} 

/* wishlist_get_max_items */

const wishlist_get_max_items = () => {
    const list_max_items = $( '#wishlist-modal-action-max-items' ).val();

    return parseInt( list_max_items, 10 );
} 















/* wishlist_set_counts */

    const wishlist_set_counts = () => {

        for( const [ list_key, list_products ] of Object.entries( wishlist_temp_data )) {

            if( list_products ){

                const list_max_items = wishlist_get_max_items();
                const list_item_count = list_products.length;

                $( '.wishlist-action-list-' + list_key + ' .wishlist-action-list-count' ).val( list_item_count );
                $( '.wishlist-action-list-' + list_key + ' .wishlist-modal-list-items-count' ).text( '(' + list_item_count + ')' );

                if( list_item_count >= list_max_items ){            
                    
                    $( '.wishlist-action-list-' + list_key + ' .wishlist-modal-list-checkbox' ).attr( 'disabled', true );
                    $( '.wishlist-action-list-' + list_key + ' label' ).addClass( 'disabled' );

                }

            }

        }

    }










/* wishlist_add_item */

const wishlist_add_item = ( product_id, list_id ) => {
    
    let list_products = wishlist_temp_data[list_id];

    if( list_products ){
        if( !list_products.includes( product_id )){
            list_products.push( product_id );
        }
    }
    
    return list_products;
}

/* wishlist_remove_item */

const wishlist_remove_item = ( product_id, list_id ) => {

    let list_products = wishlist_temp_data[list_id];

    if( list_products  ){
        const index = list_products.indexOf( product_id );

        if( index > -1 ) {
            list_products.splice( index, 1 );
        }
    }

    return list_products;
}


/* wishlist_update_list */

const wishlist_update_list = ( product_id, list_id, checked = false ) => {

    let list_data = wishlist_temp_data;
    let list_products = wishlist_temp_data[list_id];

    if( list_products && list_products.includes( product_id )){

        // remove from list

        list_products = wishlist_remove_item( product_id, list_id );

    } else {

        // add to list

        list_products = wishlist_add_item( product_id, list_id );

    }

    list_data[list_id] = list_products;
}
 

 






/* update checkbox counters */

    $( document ).on( 'change', '.wishlist-modal-list-checkbox:enabled', function( event ){

        const product_id = parseInt( $( '#wishlist-modal-product-id' ).val(), 10 );
        const list_id = parseInt( $( this ).val(), 10 );
        const checked = $( this ).is( ':checked' );


        $( '#wishlist-modal-save-button' ).prop( 'disabled', false );


        wishlist_update_list( product_id, list_id, checked );

        wishlist_set_counts();
        wishlist_set_checked( product_id );

    });







 













/* wishlist_set_checked */

const wishlist_set_checked = ( product_id ) => {

    for( const [ list_key, list_products ] of Object.entries( wishlist_temp_data )) {
        
        if( list_products && list_products.includes( product_id )) {
        
            $( '.wishlist-action-list-' + list_key + ' .wishlist-modal-list-checkbox' ).prop( 'checked', true ).attr( 'disabled', false );
            $( '.wishlist-action-list-' + list_key + ' label' ).removeClass( 'disabled' );

        }

    }

}






























    
/* render_lists */

const render_lists = ( product_id, product_listed ) => {

    $( '.wishlist-modal-list-checkbox' ).prop( 'checked', false );
/* set temp data */

    wishlist_temp_data = window.wishlist_data;
 
/* set base counts */

    wishlist_set_counts();

/* check lists that are checked for this product */

    wishlist_set_checked( product_id );
    
}

/* show_modal */

    const show_modal = ( modal ) => {

        $( '.wishlist-modal-overlay' ).hide();
        $( modal ).show();

    }

/* close_modal */

    const close_modal = () => {

        $( '.wishlist-modal-overlay' ).hide();

    }

/* close on overlay click */

    $( document ).on( 'click', '.wishlist-modal-overlay', function( event ){ 
        
        if( !$( '.wishlist-modal' ).is( event.target ) && $( '.wishlist-modal' ).has( event.target ).length === 0 ) {
        
            close_modal();
        
        }

    });

/* close on class click */

    $( document ).on( 'click', '.close-wishlist-trigger', ( event ) => {
        
        close_modal();

    });

/* close on escape button press */

    $( document ).keyup( ( event ) => {
        
        if( event.key === 'Escape' ) {
            close_modal();
        }

    });

/* .wishlist-action-trigger */

    $( document ).on( 'click', '.wishlist-action-trigger', function( event ){

        event.preventDefault();

        const product_id = parseInt( $( this ).data( 'product' ), 10 );
        const product_title = $( this ).data( 'title' );
        const product_stock = $( this ).data( 'stock' ) || 'in_stock';
        const product_listed = $( this ).data( 'listed' ) || false;
        const stock_alert_data = window.stock_alert_data || [];

    /* render lists */

        render_lists( product_id, product_listed );

    /* stock alerts */

        if( product_stock !== 'in_stock' && !stock_alert_data.includes( product_id ) ) {
             
            $( '#wishlist-modal-notifications' ).show();
            $( '#wishlist-notification-enabled' ).prop('checked', true );
            $( '#wishlist-notification-register' ).prop('checked', true );

        } else {
        
            $( '#wishlist-modal-notifications' ).hide();
            $( '#wishlist-notification-enabled' ).prop('checked', false );
            $( '#wishlist-notification-register' ).prop('checked', false );

        }

    /* update values */
 
        $( '#wishlist-modal-action .wishlist-modal-header h3 span' ).text( product_title );
        $( '#wishlist-modal-product-id' ).val( product_id );

    /* show modal */
    
        show_modal( '#wishlist-modal-action' );
   
    /* debug */

        //console.log({ product_id, product_title, product_stock, product_listed });

    });










/* close on class click */

$( document ).on( 'click', '.wishlist-modal-create-close', function( event ){

    event.preventDefault();
        
    const stepped = $( '#wishlist-create-stepped' ).val().trim();

    if( stepped === 'yes' ){
        show_modal( '#wishlist-modal-action' );
    } else {
        close_modal();
    }

});


















 


/* .wishlist-action-trigger */

$( document ).on( 'click', '.wishlist-create-trigger', function( event ){

    show_modal( '#wishlist-modal-create' );

});

 


 




$( document ).on( 'submit', '#wishlist-create-form', ( event ) => {
    
    event.preventDefault();

    const stepped = $( '#wishlist-create-stepped' ).val().trim();
    
    close_modal();

    launch_toast({ message: 'Wish List Created!', theme: 'success' });

    const ajax_data = {
        action: 'wish_list_create',
        nonce: $( '#wish_list_create_field' ).val(),
        list_name: $( '#wishlist-create-name' ).val().trim(),
        list_private: $( '#wishlist-create-private' ).is( ':checked' )
    }

    $.ajax({
        url: '/wp-admin/admin-ajax.php',
        type: 'post',
        data: ajax_data,
        dataType: 'json',
        cache: false,
        headers: { 'cache-control': 'no-cache' },
        success: function( response ){
    
            if( response ){

                if( stepped === 'yes' ){
                    
                    $( '#wishlist-modal-lists' ).append( '<li class="wishlist-action-list wishlist-action-list-' + response['list_id'] + '"><input class="wishlist-action-list-count" id="wishlist-action-list-count-' + response['list_id'] + '" type="hidden" value="0" data-count="0" required readonly><label><input class="wishlist-modal-list-item wishlist-modal-list-checkbox" name="wishlist-checkbox-' + response['list_id'] + '" type="checkbox" value="' + response['list_id'] + '"><span class="wishlist-modal-list-item-label">' + response['list_name'] + ' <span class="wishlist-modal-list-items-count">(0)</span></span></label></li>' );

                    let wishlist_create_temp_data = window.wishlist_data;

                    const list_id = response['list_id'] + ''; 

                    wishlist_create_temp_data[list_id] = [];

                    window.wishlist_data = wishlist_create_temp_data;
                    
                    show_modal( '#wishlist-modal-action' );

                    $( '#wishlist-create-name' ).val( $( '#wishlist-create-name' ).data( 'default' ));

                    const items_count = $( '.wishlist-modal-list-item' ).length;

                    if( items_count >= 5 ){
                        $( '.wishlist-create-trigger' ).hide().remove();
                    }

                } else {
                  
                    window.location.href = window.location.href.split( '#' )[0].split( '?' )[0] + '?token=' + response['list_token'];

                }

            } else {

                location.reload();

            }

        }
    });

});

/* save_wishlist_items */

const save_wishlist_items = ( data ) => {

    wishlist_save_data();

/* get data */

    const { product_id, lists_add, lists_remove } = data;

/* generate ajax data */

    const ajax_data = {
        action: 'wish_list_save',
        nonce: $( '#wish_list_save_field' ).val(),
        product_id: product_id,
        lists_add: lists_add,
        lists_remove: lists_remove
    }

/* ajax request */
    
    $.ajax({
        url: '/wp-admin/admin-ajax.php',
        type: 'post',
        data: ajax_data,
        dataType: 'json',
        cache: false,
        headers: { 'cache-control': 'no-cache' },
        success: function( response ){
            if( response['lists'] && response['lists'].length >= 1 ){
                wishlist_set_data( response['lists'] );
            }
        }
    });

    update_product_action( product_id, lists_add );
    
}

/* #wishlist-modal-save-button clicked */

$( document ).on( 'click', '#wishlist-modal-save-button', function( event ){

    event.preventDefault();

    const product_id = parseInt( $( '#wishlist-modal-product-id' ).val(), 10 );

    let lists_add = [];
    let lists_remove = [];

    $( '#wishlist-modal-lists li input[type="checkbox"]' ).each( function() {
        
        const id = parseInt( $( this ).val(), 10 );

        if( $( this ).is( ':checked' ) ) {
            
            lists_add.push( id );

        }
        else {

            lists_remove.push( id );

        }

    });
    
    close_modal();
    
    save_wishlist_items({ product_id, lists_add, lists_remove });

    launch_toast({ message: 'Wish List Updated!', theme: 'success' });

});

/* update_product_action */

const update_product_action =  ( product_id, add_list = [] ) => {   
    if( add_list.length > 0 ){
        $( '.wishlist-action[data-product="' + product_id + '"]' ).html( '<i class="fas fa-heart"></i>' );
    } else {
        $( '.wishlist-action[data-product="' + product_id + '"]' ).html( '<i class="far fa-heart"></i>' );
    }
}


/* .wishlist-share-trigger */

    $( document ).on( 'click', '.wishlist-share-trigger:not(":disabled")', function( event ){

        event.preventDefault();

        const list_link = $( this ).data( 'link' ).trim();
        const list_name = $( this ).data( 'name' ).trim();

        $( '#wishlist-email-to' ).val( '' );
        $( '#wishlist-email-address' ).val( '' );

        $( '#wishlist-share-copy-link' ).val( list_link );
        $( '#wishlist-email-link' ).val( list_link );
        $( '#wishlist-email-name' ).val( list_name );

        $( '.wishlist-share-social-links li' ).html( function( index, html ){
            return html.replace( '{WISH_LIST_LINK}', list_link );
        });

        $( '.wishlist-share-social-links li' ).html( function( index, html ){
            return html.replace( '{WISH_LIST_NAME}', list_name );
        });

        show_modal( '#wishlist-modal-share' );

    });

/* .wishlist-share-copy-link */

    $( '.wishlist-share-copy-trigger' ).click( function( event ) {
    
        event.preventDefault();
    
        $( '#wishlist-share-copy-link' ).select();
        
        document.execCommand( 'copy' );
    
        launch_toast({ message: 'Wish List Link Copied!', theme: 'success' });

    });

/* #wishlist-email-form */

    $( document ).on( 'submit', '#wishlist-email-form', ( event ) => {
        
        event.preventDefault();

        const ajax_data = {
            action: 'wish_list_email',
            nonce: $( '#wish_list_email_field' ).val(),
            email_to: $( '#wishlist-email-to' ).val().trim(),
            email_address: $( '#wishlist-email-address' ).val().trim(),
            email_from: $( '#wishlist-email-from' ).val().trim(),
            list_name: $( '#wishlist-email-name' ).val().trim(),
            list_link: $( '#wishlist-email-link' ).val().trim(),
        }

        close_modal();

        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            type: 'post',
            data: ajax_data,
            dataType: 'json',
            cache: false,
            headers: { 'cache-control': 'no-cache' },
            success: function( response ){
         
                launch_toast({ message: 'Email Sent!', theme: 'success' });
                
            }
        });    

    });

/* #wishlist-edit-delete-trigger */

    $( document ).on( 'click', '#wishlist-edit-delete-trigger', function( event ){

        event.preventDefault();

        $( this ).hide()

        $( '#wishlist-edit-delete-confirm' ).show();

    });







/* #wishlist-edit-delete-confirm-trigger */

    $( document ).on( 'click', '#wishlist-edit-delete-confirm-trigger', function( event ){

        event.preventDefault();

        $( '#wishlist-edit-delete-form' ).submit();

    });

/* form #wishlist-edit-delete-form */

    $( document ).on( 'submit', '#wishlist-edit-delete-form', ( event ) => {
        
        event.preventDefault();

        const ajax_data = {
            action: 'wish_list_delete',
            nonce: $( '#wish_list_delete_field' ).val(),
            list_id: $( '#wishlist-delete-list-id' ).val().trim()
        }
        
        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            type: 'post',
            data: ajax_data,
            dataType: 'json',
            cache: false,
            headers: { 'cache-control': 'no-cache' },
            success: function( response ){
        
                if( response ){

                    if( response['return_link'] ){
            
                        window.location.href = response['return_link'];

                    } else {
                     
                        location.reload();

                    }

                } else {

                    location.reload();

                }

            }
        });

    });








 







/* form #wishlist-edit-form */

    $( document ).on( 'submit', '#wishlist-edit-form', ( event ) => {
            
        event.preventDefault();

        const ajax_data = {
            action: 'wish_list_edit',
            nonce: $( '#wish_list_edit_field' ).val(),
            list_id: $( '#wishlist-edit-list-id' ).val().trim(),
            next_name: $( '#wishlist-edit-name' ).val().trim(),
            next_private: $( '#wishlist-edit-private' ).is( ':checked' )
        }
        
        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            type: 'post',
            data: ajax_data,
            dataType: 'json',
            cache: false,
            headers: { 'cache-control': 'no-cache' },
            success: function( response ){
        
                if( response['edited'] && response['name'] ){

                    $( '.wishlist-page-header-name' ).text( response['name'] );
                    
                    $( '#wishlist-edit-name' ).val( response['name'] );
                    $( '#wishlist-edit-name' ).data( 'current', response['name'] );
                    $( '#wishlist-edit-button-save' ).attr( 'disabled', true );

                    if( parseInt( response['private'], 10 ) > 0 ) {
                        
                        $( '#wishlist-edit-private' ).data( 'current', 'yes' );

                        $( '.wishlist-privacy-public' ).removeClass( 'active' );
                        $( '.wishlist-privacy-private' ).addClass( 'active' );

                        $( '.wishlist-share-trigger' ).addClass( 'wishlist-action-bar-button-disabled' );

                    } else {
                        
                        $( '#wishlist-edit-private' ).data( 'current', 'no' );

                        $( '.wishlist-privacy-public' ).addClass( 'active' );
                        $( '.wishlist-privacy-private' ).removeClass( 'active' );

                        $( '.wishlist-share-trigger' ).removeClass( 'wishlist-action-bar-button-disabled' );

                    }

                    close_modal();
                
                    launch_toast({ message: 'Wish List Updated!', theme: 'success' });

                } else {

                    launch_toast({ message: 'Please refresh and try again.', theme: 'delete' });

                }

            }
        });

    });










/* enable/disable form if changes made */
 
    $( '#wishlist-edit-name, #wishlist-edit-private' ).on( 'change paste keyup', function() {

        const current_name = $( '#wishlist-edit-name' ).data( 'current' ).trim();
        const next_name = $( '#wishlist-edit-name' ).val().trim();
        
        const current_private = $( '#wishlist-edit-private' ).data( 'current' ).trim().toLowerCase() === 'yes';
        const next_private = $( '#wishlist-edit-private' ).is( ':checked' );
 
        if( next_name === current_name && next_private === current_private ){

            $( '#wishlist-edit-button-save' ).attr( 'disabled', true );

        } else {
            
            $( '#wishlist-edit-button-save' ).attr( 'disabled', false );

        }

    });

















/* .wishlist-edit-trigger */

    $( document ).on( 'click', '.wishlist-edit-trigger', function( event ){

        event.preventDefault();

    /* resets */

        let current_name = $( '#wishlist-edit-name' ).data( 'current' ) + '';
        
        if( current_name ){
            current_name = current_name.trim();
        }

        const current_private = $( '#wishlist-edit-private' ).data( 'current' ).trim().toLowerCase() === 'yes';

        $( '#wishlist-edit-name' ).val( current_name ) + '';

        if( current_private === true ) {            
            $( '#wishlist-edit-private' ).prop( 'checked', true );
        } else {
            $( '#wishlist-edit-private' ).prop( 'checked', false );
        }

        $( '#wishlist-edit-delete-trigger' ).show()
        $( '#wishlist-edit-delete-confirm' ).hide();

    /* launch modal */

        show_modal( '#wishlist-modal-edit' );

    });






/* .wishlist-remove-trigger */

    $( document ).on( 'click', '.wishlist-remove-trigger', function( event ){

        event.preventDefault();

        const item_id = $( this ).closest( '.wishlist-item' ).data( 'id' );
        const item_name = $( this ).closest( '.wishlist-item' ).data( 'name' );

        //console.log({ item_id, item_name });

        $( '#wishlist-modal-remove-name' ).text( item_name );
        $( '#wishlist-remove-item-id' ).val( item_id );

        show_modal( '#wishlist-modal-remove' );

    });




/* form #wishlist-remove-form */

    $( document ).on( 'submit', '#wishlist-remove-form', ( event ) => {
            
        event.preventDefault();

        const ajax_data = {
            action: 'wish_list_remove',
            nonce: $( '#wish_list_remove_field' ).val(),
            list_id: $( '#wishlist-remove-list-id' ).val().trim(),
            item_id: $( '#wishlist-remove-item-id' ).val().trim(),
        }

        if( ajax_data['item_id'] ) {

        /* hide item */

            $( '.wishlist-item[data-id="' + ajax_data['item_id'] + '"]' ).remove();

        /* update counters */

            const updated_count = $( '.wishlist-item' ).length;

            $( '.wishlist-page-header-count' ).html( '(' + updated_count + ')' );

        /* re-sort items */
    
            const sort_by = $( '#wishlist-sort' ).val();
    
            wishlist_sort_items_init( sort_by );
        
        }

        close_modal();

        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            type: 'post',
            data: ajax_data,
            dataType: 'json',
            cache: false,
            headers: { 'cache-control': 'no-cache' },
            success: function( response ){
                
                launch_toast({ message: 'Item Removed successfully!', theme: 'success' });
                
            },
            error: function( response, status, error ){

                launch_toast({ message: 'Please refresh and try again.', theme: 'delete' });

            }
        });    

    });




/* delete_wish_list */

const delete_wish_list = ( list_id, url, nonce ) => {
    
    const ajax_data = {
        action: 'wish_list_delete',
        nonce: nonce,
        list_id
    }
    
    $.ajax({
        url: '/wp-admin/admin-ajax.php',
        type: 'post',
        data: ajax_data,
        dataType: 'json',
        cache: false,
        headers: { 'cache-control': 'no-cache' },
        success: function( response ){

            window.location.href = response['target'];

        }
    });

}





/* .wishlist-action-trigger */

$( document ).on( 'click', '.wishlist-delete-trigger', function( event ){

    event.preventDefault();

    $( '.wishlist-delete-trigger' ).attr( 'disabled', true );

    const list_id = $( this ).data( 'list' );
    const nonce = $( this ).data( 'nonce' );
    const url = $( this ).attr( 'href' );
    
    if( confirm( 'Are you sure you want to do this?' )) {

    
        launch_toast({ message: 'Wish List Deleted!', theme: 'delete' });

        delete_wish_list( list_id, url, nonce );
        

    } else {
         
        $( '.wishlist-delete-trigger' ).attr( 'disabled', false );

    }
 
});


/* wishlist_container_height */

    const wishlist_container_height = () => {

        let height = 0;
        
        $( '.wishlist-item:visible' ).each( function( index ) {

            const item_height = parseInt( $( this ).outerHeight( true ), 10 );

            height = height += item_height;

        });
        
        return height;
    }

/*  wishlist_count_results */

    const wishlist_count_results = () => {

        const count = $( '.wishlist-item:visible' ).length;
        const container_height = wishlist_container_height();
  
        $( '.wishlist-products-list' ).height( container_height );

        wishlist_empty( count );

    }

/*  wishlist_empty */

    const wishlist_empty = ( count = 0 ) => {

        if( count < 1 ) {

            $( '.wishlist-empty' ).addClass( 'visible' ).addClass( 'updated' );

        } else {

            $( '.wishlist-empty' ).removeClass( 'visible' ).removeClass( 'updated' );

        }

    }

$( '.wishlist-character-limit' ).on( 'keyup',function() {

    const value = $( this ).val().trim().replace(/\s{2,}/g, ' ');
    const count = value.length;
    const limit = 60;

    if( count <= limit ) {
        
        $( '.wishlist-name-limit span' ).text( count );

    }

    if( count >= limit ) {

        const next = value.substr( 0, limit );

        $( this ).val( next );

    }
    
});  

/* wishlist_hide_drag */

    const wishlist_hide_drag = () => {
        $( '.wishlist-item-drag-handle' ).hide();
    }

/* wishlist_show_drag */

    const wishlist_show_drag = () => {
        $( '.wishlist-item-drag-handle' ).show();
    }

/* wishlist_save_order */

    const wishlist_save_order = ( new_order ) => {
        
        const ajax_data = {
            action: 'wish_list_save_order',
            list_id: $( '#wishlist-products-list-id' ).val(),
            nonce: $( '#wish_list_products_field' ).val(),
            new_order: new_order
        }
        
        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            type: 'post',
            data: ajax_data,
            dataType: 'json',
            cache: false,
            headers: { 'cache-control': 'no-cache' }
        });

    }

/* wishlist_set_order */

    const wishlist_set_order = ( next ) => {

        if( $( '.wishlist-item:visible' ).length > 1 ) {

            let order = [];

            next.forEach( ( element, index ) => {

                const product_id = parseInt( next[index], 10 );
                const position = parseInt( ( index + 1 ), 10 );

                order.push({
                    product_id,
                    position
                });

                $( '.wishlist-item[data-id="' + product_id + '"]' ).attr( 'data-rank', position );

            });
    
            wishlist_save_order( order );

        }

    }

/* enable drag and drop if element exists */

    if( $( '#sortable' ).length > 0 ){

        const sortable_element = document.getElementById( 'sortable' );
        
        new Sortable( sortable_element, { 
            handle: '.wishlist-item-drag-handle',
            preventOnFilter: false, 
            animation: 150,
            forceFallback: true,
            scroll: true,
            bubbleScroll: true,
            store: {
                set: function( data ) {
                    wishlist_set_order( data.toArray() );
                }
            }
        });

    }
  });