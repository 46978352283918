
$( '.wishlist-character-limit' ).on( 'keyup',function() {

    const value = $( this ).val().trim().replace(/\s{2,}/g, ' ');
    const count = value.length;
    const limit = 60;

    if( count <= limit ) {
        
        $( '.wishlist-name-limit span' ).text( count );

    }

    if( count >= limit ) {

        const next = value.substr( 0, limit );

        $( this ).val( next );

    }
    
});  