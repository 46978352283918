
/* .wishlist-share-trigger */

    $( document ).on( 'click', '.wishlist-share-trigger:not(":disabled")', function( event ){

        event.preventDefault();

        const list_link = $( this ).data( 'link' ).trim();
        const list_name = $( this ).data( 'name' ).trim();

        $( '#wishlist-email-to' ).val( '' );
        $( '#wishlist-email-address' ).val( '' );

        $( '#wishlist-share-copy-link' ).val( list_link );
        $( '#wishlist-email-link' ).val( list_link );
        $( '#wishlist-email-name' ).val( list_name );

        $( '.wishlist-share-social-links li' ).html( function( index, html ){
            return html.replace( '{WISH_LIST_LINK}', list_link );
        });

        $( '.wishlist-share-social-links li' ).html( function( index, html ){
            return html.replace( '{WISH_LIST_NAME}', list_name );
        });

        show_modal( '#wishlist-modal-share' );

    });

/* .wishlist-share-copy-link */

    $( '.wishlist-share-copy-trigger' ).click( function( event ) {
    
        event.preventDefault();
    
        $( '#wishlist-share-copy-link' ).select();
        
        document.execCommand( 'copy' );
    
        launch_toast({ message: 'Wish List Link Copied!', theme: 'success' });

    });

/* #wishlist-email-form */

    $( document ).on( 'submit', '#wishlist-email-form', ( event ) => {
        
        event.preventDefault();

        const ajax_data = {
            action: 'wish_list_email',
            nonce: $( '#wish_list_email_field' ).val(),
            email_to: $( '#wishlist-email-to' ).val().trim(),
            email_address: $( '#wishlist-email-address' ).val().trim(),
            email_from: $( '#wishlist-email-from' ).val().trim(),
            list_name: $( '#wishlist-email-name' ).val().trim(),
            list_link: $( '#wishlist-email-link' ).val().trim(),
        }

        close_modal();

        $.ajax({
            url: '/wp-admin/admin-ajax.php',
            type: 'post',
            data: ajax_data,
            dataType: 'json',
            cache: false,
            headers: { 'cache-control': 'no-cache' },
            success: function( response ){
         
                launch_toast({ message: 'Email Sent!', theme: 'success' });
                
            }
        });    

    });