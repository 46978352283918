
/* toast_timer */

    let toast_timer_open;
    let toast_timer_close;

    const run_toast_timer_open = () => {
        toast_timer_open = window.setTimeout( () => {
            $( '#wishlist-toast' ).show();
            run_toast_timer_close();
        }, 200 );
    }

    const run_toast_timer_close = () => {
        toast_timer_close = window.setTimeout( () => {
            close_toast();
        }, 5000 );
    }
    
/* launch_toast */

    const launch_toast = ( data ) => {

        close_toast();

        clearTimeout( toast_timer_open );
        clearTimeout( toast_timer_close );

        const { message, theme } = data;
        
        if( !message || !theme ) return;

        $( '#wishlist-toast-container' ).removeClass().addClass( theme );
        $( '#wishlist-toast-header span' ).text( message );

        run_toast_timer_open();
    }

/* close_toast */

    const close_toast = () => {

        $( '#wishlist-toast' ).hide();

    }

/* close on toast click */

    $( document ).on( 'click', '#wishlist-toast-close-trigger', function( event ){

        close_toast();
    
    });
    