



/* delete_wish_list */

const delete_wish_list = ( list_id, url, nonce ) => {
    
    const ajax_data = {
        action: 'wish_list_delete',
        nonce: nonce,
        list_id
    }
    
    $.ajax({
        url: '/wp-admin/admin-ajax.php',
        type: 'post',
        data: ajax_data,
        dataType: 'json',
        cache: false,
        headers: { 'cache-control': 'no-cache' },
        success: function( response ){

            window.location.href = response['target'];

        }
    });

}





/* .wishlist-action-trigger */

$( document ).on( 'click', '.wishlist-delete-trigger', function( event ){

    event.preventDefault();

    $( '.wishlist-delete-trigger' ).attr( 'disabled', true );

    const list_id = $( this ).data( 'list' );
    const nonce = $( this ).data( 'nonce' );
    const url = $( this ).attr( 'href' );
    
    if( confirm( 'Are you sure you want to do this?' )) {

    
        launch_toast({ message: 'Wish List Deleted!', theme: 'delete' });

        delete_wish_list( list_id, url, nonce );
        

    } else {
         
        $( '.wishlist-delete-trigger' ).attr( 'disabled', false );

    }
 
});
