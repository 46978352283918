
 /* sort_map */

    const sort_map = {
        rank: "rank",
        lowest: "price",
        highest: "price",
        date: "date",
        name: "name",
        stock: "stock-qty",
        popularity: "popularity",
    };

 /* wishlist_get_item_offset */

    const wishlist_get_item_offset = ( heights, key ) => {

        const combined = heights.slice( 0, key );

        const offset = combined.reduce(( a, b ) => a + b, 0 );

        return offset;

    }

 /* wishlist_render_sorted_items */

    const wishlist_render_sorted_items = ( sorted ) => {
   
        const heights = sorted.map( item => {
            return parseInt( item['height'], 10 );
        });
        
        for( let i = 0; i < sorted.length; i++ ) {
        
            const key = i;
            const id = sorted[i]['id'] + '';

            const new_height = wishlist_get_item_offset( heights, key );

            $( '.wishlist-item:visible[data-id="' + id + '"]' ).css({ 'position' : 'absolute', 'left' : '0', 'top' : new_height + 'px' });

        }

    }

 /* wishlist_sort_items */

    const wishlist_sort_items = ( data, sort_by ) => {

    /* order strings alphabetically */

        if( [ 'name' ].includes( sort_by )){

            return data.sort((a, b) => a.key.localeCompare( b.key ))

        }
    
    /* sort numbers biggest to smallest */

        else if( [ 'date', 'highest', 'stock', 'popularity' ].includes( sort_by )){

            return data.sort( function( a, b ) {
                return b.key - a.key;
            });

        }
        
    /* sort numbers smallest to biggest by default */

        else {
 
            return data.sort( function( a, b ) {
                return a.key - b.key;
            });

        }

    }

 /* wishlist_sort_items_init */

    const wishlist_sort_items_init = ( sort_by = 'rank' ) => {

        wishlist_start_loading();

        let data = [];

        if( sort_by === 'rank' ){

            wishlist_show_drag();
            
            $( '.wishlist-item:visible' ).css({ 'position' : 'relative', 'left' : 'auto', 'top' : 'auto' });
            
            setTimeout( () => {

                wishlist_end_loading();
                
            }, 200 );

        } else {

            wishlist_hide_drag();

            let sort_key = sort_by;

            if( sort_map[sort_by] ){
                sort_key = sort_map[sort_by];
            }

            $( '.wishlist-item:visible' ).each( function( index ) {

                const item_id = $( this ).data( 'id' );
                const item_data = $( this ).data( sort_key );
                const item_height = parseInt( $( this ).outerHeight( true ), 10 );

                data.push({ id: item_id, key: item_data, height: item_height });

            });

            const sorted = wishlist_sort_items( data, sort_by );
            
            setTimeout( () => {
            
                $( '.wishlist-products-loading' ).hide().remove();
                
                wishlist_render_sorted_items( sorted );

                wishlist_count_results();

                wishlist_end_loading();
                
            }, 200 );
        
        }

    }

/* change sort order */

    $( document ).on( 'click', '.wishlist-sort-list li a', function( event ){

        event.preventDefault();

        const sort_by = $( this ).data( 'sort' );
        const sort_text = $( this ).text().trim();

        $( '.wishlist-sort-text' ).text( sort_text );

        wishlist_sort_items_init( sort_by );
        
        $( '.wishlist-sort-zone' ).hide();
        $( '.wishlist-sort-trigger' ).removeClass( 'active' );
        
    });

/* sorting loaders */

    const wishlist_start_loading = () => {
        
        $( '.wishlist-products-list' ).append( '<div class="wishlist-products-loading"></div>')

    }

    const wishlist_end_loading = () => {            
        
        $( '.wishlist-products-loading' ).hide().remove();

    }

/* show hide dropdown */

    $( document ).on( 'click', '.wishlist-sort-trigger', function( event ){

        event.preventDefault();

        $( '.wishlist-sort-zone' ).toggle();
        $( '.wishlist-sort-trigger' ).toggleClass( 'active' );

    });

    $( document ).mouseup(( event ) => {
            
        if( !$( '.wishlist-sort-trigger' ).is( event.target ) && $( '.wishlist-sort-zone' ).has( event.target ).length === 0 ) {
            
            $( '.wishlist-sort-zone' ).hide();
            $( '.wishlist-sort-trigger' ).removeClass( 'active' );

        }
        
    });

    $( document ).keyup( ( event ) => {
        
        if( event.key === 'Escape' ) {
            
            $( '.wishlist-sort-zone' ).hide();
            $( '.wishlist-sort-trigger' ).removeClass( 'active' );
            
        }

    });