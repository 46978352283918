
/* save_wishlist_items */

const save_wishlist_items = ( data ) => {

    wishlist_save_data();

/* get data */

    const { product_id, lists_add, lists_remove } = data;

/* generate ajax data */

    const ajax_data = {
        action: 'wish_list_save',
        nonce: $( '#wish_list_save_field' ).val(),
        product_id: product_id,
        lists_add: lists_add,
        lists_remove: lists_remove
    }

/* ajax request */
    
    $.ajax({
        url: '/wp-admin/admin-ajax.php',
        type: 'post',
        data: ajax_data,
        dataType: 'json',
        cache: false,
        headers: { 'cache-control': 'no-cache' },
        success: function( response ){
            if( response['lists'] && response['lists'].length >= 1 ){
                wishlist_set_data( response['lists'] );
            }
        }
    });

    update_product_action( product_id, lists_add );
    
}

/* #wishlist-modal-save-button clicked */

$( document ).on( 'click', '#wishlist-modal-save-button', function( event ){

    event.preventDefault();

    const product_id = parseInt( $( '#wishlist-modal-product-id' ).val(), 10 );

    let lists_add = [];
    let lists_remove = [];

    $( '#wishlist-modal-lists li input[type="checkbox"]' ).each( function() {
        
        const id = parseInt( $( this ).val(), 10 );

        if( $( this ).is( ':checked' ) ) {
            
            lists_add.push( id );

        }
        else {

            lists_remove.push( id );

        }

    });
    
    close_modal();
    
    save_wishlist_items({ product_id, lists_add, lists_remove });

    launch_toast({ message: 'Wish List Updated!', theme: 'success' });

});

/* update_product_action */

const update_product_action =  ( product_id, add_list = [] ) => {   
    if( add_list.length > 0 ){
        $( '.wishlist-action[data-product="' + product_id + '"]' ).html( '<i class="fas fa-heart"></i>' );
    } else {
        $( '.wishlist-action[data-product="' + product_id + '"]' ).html( '<i class="far fa-heart"></i>' );
    }
}
