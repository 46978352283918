
/* show_modal */

    const show_modal = ( modal ) => {

        $( '.wishlist-modal-overlay' ).hide();
        $( modal ).show();

    }

/* close_modal */

    const close_modal = () => {

        $( '.wishlist-modal-overlay' ).hide();

    }

/* close on overlay click */

    $( document ).on( 'click', '.wishlist-modal-overlay', function( event ){ 
        
        if( !$( '.wishlist-modal' ).is( event.target ) && $( '.wishlist-modal' ).has( event.target ).length === 0 ) {
        
            close_modal();
        
        }

    });

/* close on class click */

    $( document ).on( 'click', '.close-wishlist-trigger', ( event ) => {
        
        close_modal();

    });

/* close on escape button press */

    $( document ).keyup( ( event ) => {
        
        if( event.key === 'Escape' ) {
            close_modal();
        }

    });