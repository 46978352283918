
/* .wishlist-action-trigger */

    $( document ).on( 'click', '.wishlist-action-trigger', function( event ){

        event.preventDefault();

        const product_id = parseInt( $( this ).data( 'product' ), 10 );
        const product_title = $( this ).data( 'title' );
        const product_stock = $( this ).data( 'stock' ) || 'in_stock';
        const product_listed = $( this ).data( 'listed' ) || false;
        const stock_alert_data = window.stock_alert_data || [];

    /* render lists */

        render_lists( product_id, product_listed );

    /* stock alerts */

        if( product_stock !== 'in_stock' && !stock_alert_data.includes( product_id ) ) {
             
            $( '#wishlist-modal-notifications' ).show();
            $( '#wishlist-notification-enabled' ).prop('checked', true );
            $( '#wishlist-notification-register' ).prop('checked', true );

        } else {
        
            $( '#wishlist-modal-notifications' ).hide();
            $( '#wishlist-notification-enabled' ).prop('checked', false );
            $( '#wishlist-notification-register' ).prop('checked', false );

        }

    /* update values */
 
        $( '#wishlist-modal-action .wishlist-modal-header h3 span' ).text( product_title );
        $( '#wishlist-modal-product-id' ).val( product_id );

    /* show modal */
    
        show_modal( '#wishlist-modal-action' );
   
    /* debug */

        //console.log({ product_id, product_title, product_stock, product_listed });

    });