 

/* wishlist_temp_data */

let wishlist_temp_data = [];

/* wishlist_save_data */

const wishlist_save_data = () => {
        
    window.wishlist_data = wishlist_temp_data;

} 

/* wishlist_get_max_items */

const wishlist_get_max_items = () => {
    const list_max_items = $( '#wishlist-modal-action-max-items' ).val();

    return parseInt( list_max_items, 10 );
} 















/* wishlist_set_counts */

    const wishlist_set_counts = () => {

        for( const [ list_key, list_products ] of Object.entries( wishlist_temp_data )) {

            if( list_products ){

                const list_max_items = wishlist_get_max_items();
                const list_item_count = list_products.length;

                $( '.wishlist-action-list-' + list_key + ' .wishlist-action-list-count' ).val( list_item_count );
                $( '.wishlist-action-list-' + list_key + ' .wishlist-modal-list-items-count' ).text( '(' + list_item_count + ')' );

                if( list_item_count >= list_max_items ){            
                    
                    $( '.wishlist-action-list-' + list_key + ' .wishlist-modal-list-checkbox' ).attr( 'disabled', true );
                    $( '.wishlist-action-list-' + list_key + ' label' ).addClass( 'disabled' );

                }

            }

        }

    }










/* wishlist_add_item */

const wishlist_add_item = ( product_id, list_id ) => {
    
    let list_products = wishlist_temp_data[list_id];

    if( list_products ){
        if( !list_products.includes( product_id )){
            list_products.push( product_id );
        }
    }
    
    return list_products;
}

/* wishlist_remove_item */

const wishlist_remove_item = ( product_id, list_id ) => {

    let list_products = wishlist_temp_data[list_id];

    if( list_products  ){
        const index = list_products.indexOf( product_id );

        if( index > -1 ) {
            list_products.splice( index, 1 );
        }
    }

    return list_products;
}


/* wishlist_update_list */

const wishlist_update_list = ( product_id, list_id, checked = false ) => {

    let list_data = wishlist_temp_data;
    let list_products = wishlist_temp_data[list_id];

    if( list_products && list_products.includes( product_id )){

        // remove from list

        list_products = wishlist_remove_item( product_id, list_id );

    } else {

        // add to list

        list_products = wishlist_add_item( product_id, list_id );

    }

    list_data[list_id] = list_products;
}
 

 






/* update checkbox counters */

    $( document ).on( 'change', '.wishlist-modal-list-checkbox:enabled', function( event ){

        const product_id = parseInt( $( '#wishlist-modal-product-id' ).val(), 10 );
        const list_id = parseInt( $( this ).val(), 10 );
        const checked = $( this ).is( ':checked' );


        $( '#wishlist-modal-save-button' ).prop( 'disabled', false );


        wishlist_update_list( product_id, list_id, checked );

        wishlist_set_counts();
        wishlist_set_checked( product_id );

    });







 













/* wishlist_set_checked */

const wishlist_set_checked = ( product_id ) => {

    for( const [ list_key, list_products ] of Object.entries( wishlist_temp_data )) {
        
        if( list_products && list_products.includes( product_id )) {
        
            $( '.wishlist-action-list-' + list_key + ' .wishlist-modal-list-checkbox' ).prop( 'checked', true ).attr( 'disabled', false );
            $( '.wishlist-action-list-' + list_key + ' label' ).removeClass( 'disabled' );

        }

    }

}






























    
/* render_lists */

const render_lists = ( product_id, product_listed ) => {

    $( '.wishlist-modal-list-checkbox' ).prop( 'checked', false );
/* set temp data */

    wishlist_temp_data = window.wishlist_data;
 
/* set base counts */

    wishlist_set_counts();

/* check lists that are checked for this product */

    wishlist_set_checked( product_id );
    
}